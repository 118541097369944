import React from 'react';

const IconDownload = () => (
  <svg id="Download" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 537.79 537.79">
    <title>Download</title>
    <path
      className="cls-1"
      d="M463.09,466.11H74.85a21.57,21.57,0,0,0-21.49,21.5V516.3a21.55,21.55,0,0,0,21.49,21.49H462.94a21.55,21.55,0,0,0,21.49-21.49V487.61A21.19,21.19,0,0,0,463.09,466.11Z"
    />
    <path
      className="cls-1"
      d="M253.94,427.64A21.51,21.51,0,0,0,269.09,434a21.21,21.21,0,0,0,15.14-6.35l147-147a21.66,21.66,0,0,0,0-30.45l-20.35-20.34a21.66,21.66,0,0,0-30.45,0l-75.58,75.65V21.5A21.57,21.57,0,0,0,283.39,0H254.71a21.47,21.47,0,0,0-21.5,21.5v284l-75.66-75.66a21.66,21.66,0,0,0-30.45,0l-20.35,20.35a21.68,21.68,0,0,0,0,30.45Z"
    />
  </svg>
);

export default IconDownload;
